<template>
  <Layout>
    <form @submit.prevent="submit">
      <div class="section-content max-w-md mx-auto">
        <div class="form">
          <div class="form-heading text-center mb-6">
            <h5 class="mb-1">Nova senha</h5>

            <p class="text-sm">
              Informe sua nova senha, e reforce com a confirmação.
            </p>
          </div>

          <div class="body">
            <div
              :class="{
                group: true,
                groupError: $v.password.$error,
              }"
            >
              <label class="label">
                Nova senha <span class="text-red-600">*</span>
              </label>

              <input
                type="password"
                placeholder="Informe sua nova senha"
                v-model.trim="$v.password.$model"
              />

              <p class="messageError" v-if="!$v.password.required">
                Senha deve ser preenchido.
              </p>

              <p class="messageError" v-if="!$v.password.validPassword">
                Senha dever conter no minímo
                {{ $v.password.$params.minLength.min }} carecteres, <br />
                composto por letras maiúsculas, minúsculas e números.
              </p>

              <p
                class="messageError"
                v-if="!$v.password.minLength & $v.password.validPassword"
              >
                Senha dever conter no minímo
                {{ $v.password.$params.minLength.min }} carecteres.
              </p>
            </div>

            <div
              :class="{
                group: true,
                groupError: $v.confirmPassword.$error,
              }"
            >
              <label class="label">
                Confirme sua nova senha
                <span class="text-red-600">*</span>
              </label>

              <input
                type="password"
                placeholder="Confirme sua nova senha"
                v-model.trim="$v.confirmPassword.$model"
              />

              <p class="messageError" v-if="!$v.confirmPassword.sameAsPassword">
                As senhas devem ser idênticas
              </p>
            </div>
          </div>
        </div>

        <div class="form-footer">
          <Button type="submit" color="white" background="lime-600">
            Salvar nova senha
          </Button>
        </div>
      </div>
    </form>
  </Layout>
</template>

<script>
import Vue from "vue";
import { mapActions } from "vuex";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import Layout from "@/components/layout/subscription/Layout.vue";
import Button from "@/components/elements/Button.vue";
import services from "@/services";

export default Vue.extend({
  name: "SubscriptionChangePassword",
  data() {
    return {
      token: "",
      password: "",
      confirmPassword: "",
    };
  },
  components: {
    Layout,
    Button,
  },
  validations: {
    password: {
      required,
      minLength: minLength(8),
      validPassword: (password) => {
        return (
          /[a-z]/.test(password) &&
          /[A-Z]/.test(password) &&
          /[0-9]/.test(password)
        );
      },
    },
    confirmPassword: {
      sameAsPassword: sameAs("password"),
    },
  },
  methods: {
    ...mapActions(["changeLoading", "sendNotification"]),
    async submit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.changeLoading(true);

        await services.Subscription.updatePassword(this.password, this.token)
          .then(() => {
            this.changeLoading(false);

            this.sendNotification({
              type: "success",
              message: "Senha alterada com sucesso!",
            });
          })
          .catch((reason) => {
            this.changeLoading(false);

            const error = reason.response.data.error;
            this.sendNotification({
              type: "error",
              message: error.message,
            });
          });

        this.$router.push({ name: "SubscriptionLogin" });
      }
    },
  },
  mounted() {
    this.token = this.$route.query.token;
  },
});
</script>
